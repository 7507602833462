<template>
  <div class="racecard-buttons-grid">
    <div class="header">
      <div class="header-item"
           v-for="(item, key) in $attrs.raceCardHeaderItems"
           :key="key">
        {{item.title}}
      </div>
    </div>
    <div class="buttons-row"
         v-for="(row, index) in $attrs.raceCardRows"
         :key="index">
      <div class="label">
        <RacerLabel :ordinalNumber="row"
                    :color="$attrs.colors[index]"
                    height="40"
                    orientation="right">
        </RacerLabel>
      </div>
      <Button v-for="(column, key) in $attrs.raceCardColumns"
              :key="key"
              :disabled="$attrs.bettingDisabled"
              :class="{disabled: getButton(index, key).value === 0 || $attrs.bettingDisabled}"
              @change="selectOdd(getButton(index, key), column)"
              :variation="isActive(getButton(index, key)) ? 'brand' : 'default'"
              :label="getButton(index, key).value === 0 ? '-' :
                String(formatOdd(getButton(index, key).value))">
      </Button>
    </div>
  </div>
</template>

<script>
import { find } from 'lodash';
import { Button } from '@nsftx/games-sdk-js';
import RacerLabel from '@/components/RacerLabel';

export default {
  name: 'RaceCardGrid',
  components: {
    RacerLabel,
    Button,
  },
  computed: {
    selected() {
      return this.$attrs.activeButtons;
    },
  },
  methods: {
    getButton(rowIndex, columnIndex) {
      const idx = this.$attrs.direction === 'horizontal'
        ? rowIndex * this.$attrs.raceCardColumns + columnIndex
        : rowIndex + columnIndex * this.$attrs.raceCardRows;

      return this.$attrs.raceCardOdds[idx];
    },
    selectOdd(odd, col) {
      const raceCardOdd = odd;
      raceCardOdd.outcome = this.$attrs.raceCardHeaderItems[col - 1].title;
      raceCardOdd.betTypeId = odd.betTypeId;
      this.$emit('change', raceCardOdd);
    },
    isActive(odd) {
      return !!find(this.selected, {
        keyId: odd.keyId,
        value: odd.value,
        betTypeId: odd.betTypeId,
        round: odd.round,
      });
    },
    formatOdd(value) {
      if (value < 10) {
        return value.toFixed(2);
      }
      if (value >= 10 && value < 100) {
        return value.toFixed(1);
      }
      return value.toFixed(0);
    },
  },
};
</script>

<style lang="scss" scoped>
  .racecard-buttons-grid {
    position: relative;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    background-color: var(--card);
    padding: 0 8px 8px 8px;
    z-index: 0;
    .header {
      font-family: 'Roboto', sans-serif;
      display: flex;
      justify-content: space-around;
      font-size: 14px;
      color: var(--text-primary-2);
      margin-left: 17%;
      padding: 8px;
      margin-top: 16px;
      .header-item {
        user-select: none;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
      }
    }

    .buttons-row {
      display: flex;

      .label {
        width: 17%;
        margin: 1px;
      }

      .button {
        font-family: 'Roboto', sans-serif;
        flex-grow: 1;
        width: 0;
        margin: 1px;
        &.disabled {
          user-select: none;
          pointer-events: none;
        }
      }
    }
  }

  @media (min-width: 660px) {
    .racecard-buttons-grid {
      .header {
        margin-left: 180px;
    }
      .buttons-row {
        .label {
          width: 180px;
        }
      }
    }
  }
</style>
